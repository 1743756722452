import { useEffect } from 'react';
import { logoutUser } from 'redux/actions';
import { useAppDispatch } from 'redux/hooks';
import { LocalStorageKeys } from 'utils/constants';
import IdleTimer from 'utils/idle-timer';

export const useAutoLogout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = new IdleTimer({
      whitelist: ['/login', '/validation-code'],
      timeout: 604800,
      localStorageKey: LocalStorageKeys.expiredTime,
      onTimeout() {
        dispatch(logoutUser());
      },
      onExpired() {
        dispatch(logoutUser());
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);
};
